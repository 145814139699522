<template>
  <Suspense>
    <template v-if="props.name">
      <component :is="Renderer" v-bind="$attrs" />
    </template>
  </Suspense>
</template>
<script lang="ts" setup>
import { capitalise } from '@avvoka/shared'
import { computed, defineAsyncComponent } from 'vue'
import ASpinner from './ASpinner.vue'

const props = defineProps<{
  name: string
}>()

const Renderer = computed(() => {
  const componentName = capitalise(props.name || 'NotSet') + 'Icon'
  return defineAsyncComponent({
    loader: () => import(`../../assets/icons/${componentName}.vue`),
    loadingComponent: ASpinner,
    suspensible: true
  })
})
</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ASVG',
  inheritAttrs: false
})
</script>
